import { StripeError } from '@stripe/stripe-js'

import bugsnagClient from '@/lib/bugsnag'
import logger from '@/lib/logger'
import AppStore from '@/stores/AppStore'
import onStripeError from '@/utils/bugsnag-helpers/onStripeError'
import { CheckoutFormState } from '@/utils/CheckForm/handleError'

export default function handleStripeError(
  appStore: AppStore,
  checkoutFormState: CheckoutFormState,
  error: StripeError
) {
  // Reporting
  const errorMessage = `Error: stripe.confirmCardSetup returned an error - ${error.code}: ${error.message}`
  logger.error(errorMessage)
  bugsnagClient.addOnError((event) => {
    event.addMetadata('restaurant', 'id', appStore.state.restaurantId)
    event.addMetadata('booking', 'id', appStore.state.pendingBookingId)
  })
  bugsnagClient.notify(errorMessage, onStripeError(error))

  // Client
  checkoutFormState.errorMessage = error.message
  checkoutFormState.isLoading = false
}
