import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaToggleButtonsDefaultVariants = {
  "size": "small",
  "iconOnly": false,
  "hierarchy": "primary"
}
const aromaToggleButtonsCompoundVariants = []

const aromaToggleButtonsSlotNames = [
  [
    "wrapper",
    "aroma-toggle-buttons__wrapper"
  ],
  [
    "container",
    "aroma-toggle-buttons__container"
  ],
  [
    "item",
    "aroma-toggle-buttons__item"
  ]
]
const aromaToggleButtonsSlotFns = /* @__PURE__ */ aromaToggleButtonsSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaToggleButtonsDefaultVariants, getSlotCompoundVariant(aromaToggleButtonsCompoundVariants, slotName))])

const aromaToggleButtonsFn = memo((props = {}) => {
  return Object.fromEntries(aromaToggleButtonsSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaToggleButtonsVariantKeys = [
  "size",
  "iconOnly",
  "hierarchy"
]
const getVariantProps = (variants) => ({ ...aromaToggleButtonsDefaultVariants, ...compact(variants) })

export const aromaToggleButtons = /* @__PURE__ */ Object.assign(aromaToggleButtonsFn, {
  __recipe__: false,
  __name__: 'aromaToggleButtons',
  raw: (props) => props,
  variantKeys: aromaToggleButtonsVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium"
  ],
  "iconOnly": [
    "true",
    "false"
  ],
  "hierarchy": [
    "primary",
    "warning",
    "error"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaToggleButtonsVariantKeys)
  },
  getVariantProps
})