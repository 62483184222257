import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaRadioDefaultVariants = {
  "hierarchy": "bold"
}
const aromaRadioCompoundVariants = []

const aromaRadioSlotNames = [
  [
    "groupContainer",
    "aroma-radio__groupContainer"
  ],
  [
    "itemContainer",
    "aroma-radio__itemContainer"
  ],
  [
    "control",
    "aroma-radio__control"
  ],
  [
    "label",
    "aroma-radio__label"
  ],
  [
    "description",
    "aroma-radio__description"
  ],
  [
    "errorContainer",
    "aroma-radio__errorContainer"
  ],
  [
    "errorText",
    "aroma-radio__errorText"
  ],
  [
    "indicator",
    "aroma-radio__indicator"
  ],
  [
    "icon",
    "aroma-radio__icon"
  ],
  [
    "title",
    "aroma-radio__title"
  ],
  [
    "subtitle",
    "aroma-radio__subtitle"
  ]
]
const aromaRadioSlotFns = /* @__PURE__ */ aromaRadioSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaRadioDefaultVariants, getSlotCompoundVariant(aromaRadioCompoundVariants, slotName))])

const aromaRadioFn = memo((props = {}) => {
  return Object.fromEntries(aromaRadioSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaRadioVariantKeys = [
  "hierarchy"
]
const getVariantProps = (variants) => ({ ...aromaRadioDefaultVariants, ...compact(variants) })

export const aromaRadio = /* @__PURE__ */ Object.assign(aromaRadioFn, {
  __recipe__: false,
  __name__: 'aromaRadio',
  raw: (props) => props,
  variantKeys: aromaRadioVariantKeys,
  variantMap: {
  "hierarchy": [
    "bold",
    "subtle"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaRadioVariantKeys)
  },
  getVariantProps
})