import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaTextareaDefaultVariants = {}
const aromaTextareaCompoundVariants = []

const aromaTextareaSlotNames = [
  [
    "textarea",
    "aroma-textarea__textarea"
  ],
  [
    "container",
    "aroma-textarea__container"
  ]
]
const aromaTextareaSlotFns = /* @__PURE__ */ aromaTextareaSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaTextareaDefaultVariants, getSlotCompoundVariant(aromaTextareaCompoundVariants, slotName))])

const aromaTextareaFn = memo((props = {}) => {
  return Object.fromEntries(aromaTextareaSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaTextareaVariantKeys = [
  "status"
]
const getVariantProps = (variants) => ({ ...aromaTextareaDefaultVariants, ...compact(variants) })

export const aromaTextarea = /* @__PURE__ */ Object.assign(aromaTextareaFn, {
  __recipe__: false,
  __name__: 'aromaTextarea',
  raw: (props) => props,
  variantKeys: aromaTextareaVariantKeys,
  variantMap: {
  "status": [
    "error",
    "warning",
    "readOnly"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaTextareaVariantKeys)
  },
  getVariantProps
})