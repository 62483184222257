import { Button, Icon } from '@zenchef/ds-react'
import { useContext } from 'react'

import { isTablebookerVoucherParam } from '@/types/types'
import getTablebookerShopUrl from '@/utils/getTablebookerShopUrl'
import getTestId from '@/utils/getTestId'
import StoresContext from '@/utils/StoresContext'

export default function ShopRedirectionButton() {
  const { appStore } = useContext(StoresContext)
  const { language, voucherParam, theme, shouldDisplayShopVoucher } = appStore.state

  if (!(isTablebookerVoucherParam(voucherParam) && shouldDisplayShopVoucher)) return null

  const [, primaryColor] = theme.colors.primary?.split('#')

  const redirectToShop = () => {
    if (voucherParam.tablebooker_shop_id) {
      const shopUrl = getTablebookerShopUrl({
        tablebookerShopId: voucherParam.tablebooker_shop_id,
        primaryColor,
        language
      })

      window.open(shopUrl, '_blank')
    }
  }

  return (
    <Button
      data-testid={getTestId('shop', 'btn')}
      onClick={redirectToShop}
      hierarchy='brand-reversed-subtle'
      size='medium'
      border='unset'
      iconOnly>
      <Icon name='gift' fontSize='20px' />
    </Button>
  )
}
