import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaAvatarDefaultVariants = {
  "size": "medium",
  "colorTheme": "neutral",
  "hierarchy": "subtle"
}
const aromaAvatarCompoundVariants = [
  {
    "hierarchy": "subtle",
    "colorTheme": "neutral",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.neutral.base-subtle",
        "color": "content.neutral.base.subtlest",
        "_hover": {
          "backgroundColor": "background.decorative.neutral.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "red",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.red.base-subtle",
        "color": "content.decorative.red",
        "_hover": {
          "backgroundColor": "background.decorative.red.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "orange",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.orange.base-subtle",
        "color": "content.decorative.orange",
        "_hover": {
          "backgroundColor": "background.decorative.orange.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "yellow",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.yellow.base-subtle",
        "color": "content.decorative.yellow",
        "_hover": {
          "backgroundColor": "background.decorative.yellow.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "green",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.green.base-subtle",
        "color": "content.decorative.green",
        "_hover": {
          "backgroundColor": "background.decorative.green.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "blue",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.blue.base-subtle",
        "color": "content.decorative.blue",
        "_hover": {
          "backgroundColor": "background.decorative.blue.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "purple",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.purple.base-subtle",
        "color": "content.decorative.purple",
        "_hover": {
          "backgroundColor": "background.decorative.purple.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "pink",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.pink.base-subtle",
        "color": "content.decorative.pink",
        "_hover": {
          "backgroundColor": "background.decorative.pink.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "neutral",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.neutral.base-bolder",
        "color": "content.neutral.reverse.bold",
        "_hover": {
          "backgroundColor": "background.decorative.neutral.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "red",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.red.base-bolder",
        "color": "content.neutral.reverse.bold",
        "_hover": {
          "backgroundColor": "background.decorative.red.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "orange",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.orange.base-bolder",
        "color": "content.neutral.reverse.bold",
        "_hover": {
          "backgroundColor": "background.decorative.orange.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "yellow",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.yellow.base-bolder",
        "color": "content.neutral.reverse.bold",
        "_hover": {
          "backgroundColor": "background.decorative.yellow.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "green",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.green.base-bolder",
        "color": "content.neutral.reverse.bold",
        "_hover": {
          "backgroundColor": "background.decorative.green.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "blue",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.blue.base-bolder",
        "color": "content.neutral.reverse.bold",
        "_hover": {
          "backgroundColor": "background.decorative.blue.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "purple",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.purple.base-bolder",
        "color": "content.neutral.reverse.bold",
        "_hover": {
          "backgroundColor": "background.decorative.purple.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "pink",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.pink.base-bolder",
        "color": "content.neutral.reverse.bold",
        "_hover": {
          "backgroundColor": "background.decorative.pink.hover-bolder"
        }
      }
    }
  }
]

const aromaAvatarSlotNames = [
  [
    "container",
    "aroma-avatar__container"
  ],
  [
    "image",
    "aroma-avatar__image"
  ],
  [
    "badge",
    "aroma-avatar__badge"
  ],
  [
    "title",
    "aroma-avatar__title"
  ],
  [
    "subtitle",
    "aroma-avatar__subtitle"
  ],
  [
    "wrap",
    "aroma-avatar__wrap"
  ],
  [
    "textWrap",
    "aroma-avatar__textWrap"
  ],
  [
    "abbreviation",
    "aroma-avatar__abbreviation"
  ]
]
const aromaAvatarSlotFns = /* @__PURE__ */ aromaAvatarSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaAvatarDefaultVariants, getSlotCompoundVariant(aromaAvatarCompoundVariants, slotName))])

const aromaAvatarFn = memo((props = {}) => {
  return Object.fromEntries(aromaAvatarSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaAvatarVariantKeys = [
  "hierarchy",
  "colorTheme",
  "size",
  "withBadge"
]
const getVariantProps = (variants) => ({ ...aromaAvatarDefaultVariants, ...compact(variants) })

export const aromaAvatar = /* @__PURE__ */ Object.assign(aromaAvatarFn, {
  __recipe__: false,
  __name__: 'aromaAvatar',
  raw: (props) => props,
  variantKeys: aromaAvatarVariantKeys,
  variantMap: {
  "hierarchy": [
    "subtle",
    "bolder"
  ],
  "colorTheme": [
    "neutral",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "purple",
    "pink",
    "disabled"
  ],
  "size": [
    "small",
    "medium",
    "xl",
    "2xl",
    "jumbo"
  ],
  "withBadge": [
    "false",
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaAvatarVariantKeys)
  },
  getVariantProps
})