import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaNavButtonDefaultVariants = {}
const aromaNavButtonCompoundVariants = []

const aromaNavButtonSlotNames = [
  [
    "root",
    "aroma-nav-button__root"
  ],
  [
    "badge",
    "aroma-nav-button__badge"
  ]
]
const aromaNavButtonSlotFns = /* @__PURE__ */ aromaNavButtonSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaNavButtonDefaultVariants, getSlotCompoundVariant(aromaNavButtonCompoundVariants, slotName))])

const aromaNavButtonFn = memo((props = {}) => {
  return Object.fromEntries(aromaNavButtonSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaNavButtonVariantKeys = []
const getVariantProps = (variants) => ({ ...aromaNavButtonDefaultVariants, ...compact(variants) })

export const aromaNavButton = /* @__PURE__ */ Object.assign(aromaNavButtonFn, {
  __recipe__: false,
  __name__: 'aromaNavButton',
  raw: (props) => props,
  variantKeys: aromaNavButtonVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, aromaNavButtonVariantKeys)
  },
  getVariantProps
})