import { Badge, Icon } from '@zenchef/ds-react'
import { HTMLStyledProps } from '@zenchef/styled-system/types'
import React from 'react'

import Subtitle from '@/components/redesign/common/Subtitle'

export default function HighlightedExperienceBadge({
  children,
  subtle,
  ...props
}: {
  children?: React.ReactNode
  subtle?: boolean
} & HTMLStyledProps<'span'>) {
  return (
    <Subtitle
      width='fit-content'
      zIndex={1}
      {...(children
        ? {
            pl: 'padding.0,5'
          }
        : {
            bg: 'transparent',
            p: '0',
            height: 'unset'
          })}
      color='content.neutral.bold'
      gap='gap.2'
      {...props}>
      <Badge
        icon
        outlined
        bg='background.brand.bold'
        {...(subtle
          ? {
              outlineColor: 'background.neutral-on-brand.base.subtle',
              color: 'content.neutral.subtlest'
            }
          : {
              outlineColor: 'background.neutral-on-brand.base.bold',
              color: 'content.neutral.bold'
            })}>
        <Icon name='chef-hat-solid' fontSize='{sizes.3xs}!' />
      </Badge>
      {children}
    </Subtitle>
  )
}
