import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaToastDefaultVariants = {
  "function": "neutral"
}
const aromaToastCompoundVariants = []

const aromaToastSlotNames = [
  [
    "title",
    "aroma-toast__title"
  ],
  [
    "body",
    "aroma-toast__body"
  ],
  [
    "description",
    "aroma-toast__description"
  ],
  [
    "root",
    "aroma-toast__root"
  ],
  [
    "icon",
    "aroma-toast__icon"
  ],
  [
    "actionTrigger",
    "aroma-toast__actionTrigger"
  ]
]
const aromaToastSlotFns = /* @__PURE__ */ aromaToastSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaToastDefaultVariants, getSlotCompoundVariant(aromaToastCompoundVariants, slotName))])

const aromaToastFn = memo((props = {}) => {
  return Object.fromEntries(aromaToastSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaToastVariantKeys = [
  "function"
]
const getVariantProps = (variants) => ({ ...aromaToastDefaultVariants, ...compact(variants) })

export const aromaToast = /* @__PURE__ */ Object.assign(aromaToastFn, {
  __recipe__: false,
  __name__: 'aromaToast',
  raw: (props) => props,
  variantKeys: aromaToastVariantKeys,
  variantMap: {
  "function": [
    "neutral",
    "info",
    "success",
    "warning",
    "error"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaToastVariantKeys)
  },
  getVariantProps
})