import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaCarouselDefaultVariants = {}
const aromaCarouselCompoundVariants = []

const aromaCarouselSlotNames = [
  [
    "root",
    "aroma-carousel__root"
  ],
  [
    "viewport",
    "aroma-carousel__viewport"
  ],
  [
    "itemGroup",
    "aroma-carousel__itemGroup"
  ],
  [
    "item",
    "aroma-carousel__item"
  ],
  [
    "nextTrigger",
    "aroma-carousel__nextTrigger"
  ],
  [
    "prevTrigger",
    "aroma-carousel__prevTrigger"
  ],
  [
    "indicatorGroup",
    "aroma-carousel__indicatorGroup"
  ],
  [
    "indicator",
    "aroma-carousel__indicator"
  ],
  [
    "control",
    "aroma-carousel__control"
  ],
  [
    "trigger",
    "aroma-carousel__trigger"
  ],
  [
    "controlWrapper",
    "aroma-carousel__controlWrapper"
  ]
]
const aromaCarouselSlotFns = /* @__PURE__ */ aromaCarouselSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaCarouselDefaultVariants, getSlotCompoundVariant(aromaCarouselCompoundVariants, slotName))])

const aromaCarouselFn = memo((props = {}) => {
  return Object.fromEntries(aromaCarouselSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaCarouselVariantKeys = []
const getVariantProps = (variants) => ({ ...aromaCarouselDefaultVariants, ...compact(variants) })

export const aromaCarousel = /* @__PURE__ */ Object.assign(aromaCarouselFn, {
  __recipe__: false,
  __name__: 'aromaCarousel',
  raw: (props) => props,
  variantKeys: aromaCarouselVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, aromaCarouselVariantKeys)
  },
  getVariantProps
})