// COPIED FROM @zenchef/ui

import React from 'react' //eslint-disable-line
import styled, { withTheme, keyframes, css } from 'styled-components'
import { Box } from 'rebass'
import { token } from '@zenchef/styled-system/tokens'

const animationName = keyframes`
    0%,
    80%,
    100% {
         transform: scale(0);
         opacity: 0;
     }
     40% {
        transform: scale(1);
        opacity: 1;
    }   
`

const animation = ({ delay = '0s' }) => css`
  ${animationName} 2s infinite alternate ${delay};
`

const Bounce = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  animation: ${animation};
`

Bounce.defaultProps = {
  bg: token.var('colors.background.brand.adaptive')
}

export default withTheme((props) => {
  return (
    <Box {...props} css='width: 50px; text-align: center;'>
      <Bounce delay='-0.32s' bg={props.bounceColor} />
      &nbsp;
      <Bounce delay='-0.16s' bg={props.bounceColor} />
      &nbsp;
      <Bounce bg={props.bounceColor} />
    </Box>
  )
})
