import { Button, Icon, Text } from '@zenchef/ds-react'
import { Box, styled } from '@zenchef/styled-system/jsx'
import { observer, useLocalObservable } from 'mobx-react'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import { modalHeaderItemRecipe } from '@/components/Modal/ModalRecipes'
import isImageValid from '@/utils/isImageValid'
import StoresContext from '@/utils/StoresContext'

const ModalHeaderItem = observer(() => {
  const { appStore } = useContext(StoresContext)
  const router = useRouter()

  const { selectedHighlightedOffer } = appStore.state
  const { unselectHighlightedOffer } = appStore

  const state = useLocalObservable(() => ({
    imageError: false
  }))

  useEffect(() => {
    // This checks if the logo manages to load.
    // We can't do this directly on image onError because
    // the event is triggered before hydration.
    if (appStore.state.logo) {
      isImageValid(appStore.state.logo).then((isValid) => {
        if (!isValid) {
          state.imageError = true
        }
      })
    }
  }, [appStore.state.logo, state])

  const handleClick = () => {
    if (selectedHighlightedOffer) {
      unselectHighlightedOffer()
    }
  }

  const showLogo = !!appStore.state.logo && !state.imageError

  const showBackButton = selectedHighlightedOffer && router.pathname === '/results'

  return (
    <>
      {showBackButton ? (
        <Button hierarchy='brand-reversed-subtler' onClick={handleClick} iconOnly>
          <Icon name='chevron-left' fontSize='icon.base' />
        </Button>
      ) : null}
      <Box
        className={modalHeaderItemRecipe({
          withBackButton: !!showBackButton,
          withLogo: showLogo,
          whiteLogo: !!appStore.state.query?.white_logo
        })}>
        {showLogo ? (
          <styled.img
            src={appStore.state.logo}
            height='{sizes.m}'
            alt={appStore.state.name}
            onError={(e) => {
              state.imageError = true
              e.currentTarget.src = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>`
            }}
          />
        ) : (
          <Text textStyle='title.m' color='colorPalette.neutral.bold'>
            {appStore.state.name}
          </Text>
        )}
      </Box>
    </>
  )
})

export default ModalHeaderItem
