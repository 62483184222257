import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaBucketDefaultVariants = {
  "size": "large"
}
const aromaBucketCompoundVariants = []

const aromaBucketSlotNames = [
  [
    "wrap",
    "aroma-bucket__wrap"
  ],
  [
    "container",
    "aroma-bucket__container"
  ],
  [
    "sectionWrap",
    "aroma-bucket__sectionWrap"
  ],
  [
    "section",
    "aroma-bucket__section"
  ],
  [
    "list",
    "aroma-bucket__list"
  ],
  [
    "emptySide",
    "aroma-bucket__emptySide"
  ],
  [
    "bucketList",
    "aroma-bucket__bucketList"
  ],
  [
    "bucketListItem",
    "aroma-bucket__bucketListItem"
  ],
  [
    "art",
    "aroma-bucket__art"
  ]
]
const aromaBucketSlotFns = /* @__PURE__ */ aromaBucketSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaBucketDefaultVariants, getSlotCompoundVariant(aromaBucketCompoundVariants, slotName))])

const aromaBucketFn = memo((props = {}) => {
  return Object.fromEntries(aromaBucketSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaBucketVariantKeys = [
  "size",
  "popupPosition"
]
const getVariantProps = (variants) => ({ ...aromaBucketDefaultVariants, ...compact(variants) })

export const aromaBucket = /* @__PURE__ */ Object.assign(aromaBucketFn, {
  __recipe__: false,
  __name__: 'aromaBucket',
  raw: (props) => props,
  variantKeys: aromaBucketVariantKeys,
  variantMap: {
  "size": [
    "large",
    "small"
  ],
  "popupPosition": [
    "top",
    "bottom",
    "center"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaBucketVariantKeys)
  },
  getVariantProps
})