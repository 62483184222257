import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaFieldDefaultVariants = {}
const aromaFieldCompoundVariants = []

const aromaFieldSlotNames = [
  [
    "root",
    "aroma-field__root"
  ],
  [
    "hintText",
    "aroma-field__hintText"
  ]
]
const aromaFieldSlotFns = /* @__PURE__ */ aromaFieldSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaFieldDefaultVariants, getSlotCompoundVariant(aromaFieldCompoundVariants, slotName))])

const aromaFieldFn = memo((props = {}) => {
  return Object.fromEntries(aromaFieldSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaFieldVariantKeys = [
  "status"
]
const getVariantProps = (variants) => ({ ...aromaFieldDefaultVariants, ...compact(variants) })

export const aromaField = /* @__PURE__ */ Object.assign(aromaFieldFn, {
  __recipe__: false,
  __name__: 'aromaField',
  raw: (props) => props,
  variantKeys: aromaFieldVariantKeys,
  variantMap: {
  "status": [
    "error",
    "warning",
    "success",
    "loading"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaFieldVariantKeys)
  },
  getVariantProps
})