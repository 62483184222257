import { Dropdown } from '@zenchef/ds-react'
import { cva } from '@zenchef/styled-system/css'
import { observer } from 'mobx-react-lite'
import Router from 'next/router'
import { useContext } from 'react'

import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

interface LanguageButtonProps {}

const appearenceRecipe = cva({
  base: {
    width: 'auto',

    '& [data-part="trigger"]': {
      bgColor: 'transparent',
      borderColor: 'transparent',
      width: 'xl',
      height: 'xl',
      borderRadius: 'l',
      cursor: 'pointer',

      '& span': {
        color: 'content.neutral.subtle',
        textStyle: 'title.s',
        textTransform: 'uppercase'
      },

      '&:hover, &[data-state="open"]': {
        bgColor: 'background.neutral.reverse.subtlest-active'
      },

      _focusVisible: {
        shadow: 'effect.focus-ring.neutral',
        outline: 'none',
        borderColor: 'border.brand.brand-brand'
      }
    }
  }
})

const LanguageButton = observer<LanguageButtonProps>(() => {
  const { appStore } = useContext(StoresContext)
  const { language_availabilities, hideLang, language } = appStore.state
  const { t, i18n } = useTranslation()

  const lang = (language_availabilities.find((l) => l.toLowerCase() === language) ?? 'fr').toLowerCase()

  const changeLanguage = async (language: string) => {
    await i18n.changeLanguage(language)
    appStore.state.language = language
    const { lang: _routerLang, ...queryParams } = Router.query

    Router.push({
      pathname: Router.pathname,
      query: {
        ...queryParams,
        ...(language === appStore.state.restaurantLanguage ? {} : { lang: language })
      }
    })
  }

  if (hideLang) {
    return <div style={{ width: '56px', height: '32px' }} />
  }

  return (
    <Dropdown
      items={language_availabilities.map((lng) => ({
        label: t(`language.${lng}`),
        value: lng
      }))}
      iconOnly
      prefix={lang}
      value={lang}
      className={appearenceRecipe()}
      onChange={changeLanguage}
      aria-label={lang}
      contentWidth={240}
      maxContentHeight={500}
    />
  )
})

LanguageButton.displayName = 'LanguageButton'

export default LanguageButton
