import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect } from 'react'

import bugsnagClient from '@/lib/bugsnag'
import logger from '@/lib/logger'
import errorMessageHandler from '@/utils/errorMessageHandler'
import StoresContext from '@/utils/StoresContext'

export default function useAdyenRedirectResult() {
  const router = useRouter()
  const { appStore } = useContext(StoresContext)
  const isNetworkError = useCallback((error) => {
    return error.type === 'fetch_error' || error.message === 'Network Error'
  }, [])

  useEffect(() => {
    const { redirectResult } = router.query
    if (!redirectResult || !appStore.state.has_adyen_for_prepayment) {
      return
    }

    const authorizeAdyenPayment = async () => {
      try {
        const response = await appStore.authorizeAdyenPayment({
          adyenRedirectResult: typeof redirectResult === 'string' ? redirectResult : redirectResult[0]
        })

        appStore.state.createdCustomerSheet.relatedBookingStatus = response.metaData.booking_status
        appStore.state.createdCustomerSheet.id = response.metaData.customer_sheet_id

        const query = { ...appStore.state.query }
        ;['force-prepayment', 'token', 'redirectResult'].forEach((param) => {
          delete query[param]
        })

        router.push({
          pathname: '/thank_you',
          query
        })
      } catch (error) {
        appStore.state.error = errorMessageHandler(error)
        if (!isNetworkError(error)) {
          logger.error(error)
          bugsnagClient.notify(error)
        }
      }
    }

    authorizeAdyenPayment()
  }, [
    appStore,
    appStore.state.has_adyen_for_prepayment,
    router,
    router.query.redirectResult,
    isNetworkError,
    appStore.state.query.rid
  ])
}
