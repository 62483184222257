import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaDialogDefaultVariants = {
  "size": "small"
}
const aromaDialogCompoundVariants = []

const aromaDialogSlotNames = [
  [
    "content",
    "aroma-dialog__content"
  ],
  [
    "backdrop",
    "aroma-dialog__backdrop"
  ],
  [
    "positioner",
    "aroma-dialog__positioner"
  ],
  [
    "footer",
    "aroma-dialog__footer"
  ]
]
const aromaDialogSlotFns = /* @__PURE__ */ aromaDialogSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaDialogDefaultVariants, getSlotCompoundVariant(aromaDialogCompoundVariants, slotName))])

const aromaDialogFn = memo((props = {}) => {
  return Object.fromEntries(aromaDialogSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaDialogVariantKeys = [
  "size"
]
const getVariantProps = (variants) => ({ ...aromaDialogDefaultVariants, ...compact(variants) })

export const aromaDialog = /* @__PURE__ */ Object.assign(aromaDialogFn, {
  __recipe__: false,
  __name__: 'aromaDialog',
  raw: (props) => props,
  variantKeys: aromaDialogVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaDialogVariantKeys)
  },
  getVariantProps
})