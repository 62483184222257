import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaCheckboxDefaultVariants = {
  "hierarchy": "bold",
  "size": "small"
}
const aromaCheckboxCompoundVariants = []

const aromaCheckboxSlotNames = [
  [
    "container",
    "aroma-checkbox__container"
  ],
  [
    "control",
    "aroma-checkbox__control"
  ],
  [
    "title",
    "aroma-checkbox__title"
  ],
  [
    "subtitle",
    "aroma-checkbox__subtitle"
  ],
  [
    "label",
    "aroma-checkbox__label"
  ],
  [
    "icon",
    "aroma-checkbox__icon"
  ],
  [
    "errorText",
    "aroma-checkbox__errorText"
  ],
  [
    "errorContainer",
    "aroma-checkbox__errorContainer"
  ]
]
const aromaCheckboxSlotFns = /* @__PURE__ */ aromaCheckboxSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaCheckboxDefaultVariants, getSlotCompoundVariant(aromaCheckboxCompoundVariants, slotName))])

const aromaCheckboxFn = memo((props = {}) => {
  return Object.fromEntries(aromaCheckboxSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaCheckboxVariantKeys = [
  "hierarchy",
  "size"
]
const getVariantProps = (variants) => ({ ...aromaCheckboxDefaultVariants, ...compact(variants) })

export const aromaCheckbox = /* @__PURE__ */ Object.assign(aromaCheckboxFn, {
  __recipe__: false,
  __name__: 'aromaCheckbox',
  raw: (props) => props,
  variantKeys: aromaCheckboxVariantKeys,
  variantMap: {
  "hierarchy": [
    "bold",
    "subtle"
  ],
  "size": [
    "small",
    "medium"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaCheckboxVariantKeys)
  },
  getVariantProps
})