import { Button } from '@zenchef/ds-react'
import { css } from '@zenchef/styled-system/css'
import { HStack } from '@zenchef/styled-system/jsx'
import { useContext } from 'react'

import ZenchefLogo from '@/components/redesign/common/ZenchefLogo'
import ShopRedirectionButton from '@/components/ShopRedirectionButton'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

const MadeByZenchef = () => {
  const { t } = useTranslation()
  const { appStore } = useContext(StoresContext)

  return (
    <HStack
      gap='gap.2'
      padding='gap.1'
      width='100%'
      className={css({
        '&:has(> :nth-child(2))': {
          justifyContent: 'space-between'
        }
      })}
      justifyContent='center'>
      <HStack gap='gap.0'>
        <Button
          hierarchy='brand-reversed-subtle'
          size='medium'
          border='unset'
          iconOnly
          onClick={() => window.open(appStore.getMarketingLink('poweredBy-footer'), '_blank')}>
          <ZenchefLogo />
        </Button>
        {t('made_possible_by_zenchef')}
      </HStack>
      <ShopRedirectionButton />
    </HStack>
  )
}

export default MadeByZenchef
