import { Flex, styled } from '@zenchef/styled-system/jsx'

const Well = styled(Flex, {
  base: {
    wordBreak: 'break-word',
    borderRadius: '8px',
    border: 'solid',
    borderWidth: 'm',
    borderColor: 'border.neutral.subtle',
    color: 'background.brand.adaptive',
    flexWrap: 'nowrap',
    alignItems: 'center',
    background: 'white',
    justifyContent: ['center', 'space-between']
  }
})

export default Well
