import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaBannerDefaultVariants = {
  "colorTheme": "neutral",
  "spot": "float",
  "form": "bar"
}
const aromaBannerCompoundVariants = [
  {
    "form": "bar",
    "colorTheme": "oat",
    "css": {
      "description": {
        "textStyle": "paragraph.l.regular"
      }
    }
  },
  {
    "form": "bar",
    "colorTheme": "blue",
    "css": {
      "description": {
        "textStyle": "paragraph.l.regular"
      }
    }
  },
  {
    "form": "bar",
    "colorTheme": "oat",
    "showLogo": true,
    "css": {
      "desktopLogo": {
        "maxWidth": "107px",
        "display": "none",
        "xs": {
          "display": "flex"
        }
      },
      "mobileLogo": {
        "maxWidth": "m",
        "display": "block",
        "xs": {
          "display": "none"
        }
      }
    }
  },
  {
    "form": "bar",
    "colorTheme": "blue",
    "showLogo": true,
    "css": {
      "desktopLogo": {
        "maxWidth": "107px",
        "display": "none",
        "xs": {
          "display": "flex"
        }
      },
      "mobileLogo": {
        "display": "block",
        "xs": {
          "display": "none"
        }
      }
    }
  },
  {
    "form": "bar",
    "spot": "float",
    "css": {
      "container": {
        "width": "calc(100% - 32px)",
        "xs": {
          "width": "calc(100% - 48px)"
        },
        "md": {
          "maxWidth": "1120px"
        }
      }
    }
  },
  {
    "form": "bar",
    "isRemovable": true,
    "spot": "float",
    "css": {
      "content": {
        "marginRight": "48px"
      }
    }
  },
  {
    "form": "bar",
    "isRemovable": true,
    "spot": "fixed",
    "css": {
      "content": {
        "marginRight": "48px",
        "lg": {
          "marginRight": "auto"
        }
      }
    }
  },
  {
    "form": "bar",
    "showLogo": true,
    "spot": "float",
    "colorTheme": "blue",
    "css": {
      "content": {
        "marginLeft": "margin.10",
        "xs": {
          "marginLeft": "118px"
        }
      }
    }
  },
  {
    "form": "bar",
    "showLogo": true,
    "spot": "fixed",
    "colorTheme": "blue",
    "css": {
      "content": {
        "marginLeft": "margin.10",
        "xs": {
          "marginLeft": "118px"
        },
        "md": {
          "marginLeft": "auto"
        }
      }
    }
  },
  {
    "form": "bar",
    "showLogo": true,
    "spot": "float",
    "colorTheme": "oat",
    "css": {
      "content": {
        "marginLeft": "margin.10",
        "xs": {
          "marginLeft": "118px"
        }
      }
    }
  },
  {
    "form": "bar",
    "showLogo": true,
    "spot": "fixed",
    "colorTheme": "oat",
    "css": {
      "content": {
        "marginLeft": "margin.10",
        "xs": {
          "marginLeft": "118px"
        },
        "md": {
          "marginLeft": "auto"
        }
      }
    }
  },
  {
    "form": "bar",
    "colorTheme": "neutral",
    "css": {
      "contextualIconWrap": {
        "display": "block"
      }
    }
  },
  {
    "form": "bar",
    "colorTheme": "info",
    "css": {
      "contextualIconWrap": {
        "display": "block"
      }
    }
  },
  {
    "form": "bar",
    "colorTheme": "success",
    "css": {
      "contextualIconWrap": {
        "display": "block"
      }
    }
  },
  {
    "form": "bar",
    "colorTheme": "warning",
    "css": {
      "contextualIconWrap": {
        "display": "block"
      }
    }
  },
  {
    "form": "bar",
    "colorTheme": "error",
    "css": {
      "contextualIconWrap": {
        "display": "block"
      }
    }
  }
]

const aromaBannerSlotNames = [
  [
    "container",
    "aroma-banner__container"
  ],
  [
    "logo",
    "aroma-banner__logo"
  ],
  [
    "content",
    "aroma-banner__content"
  ],
  [
    "text",
    "aroma-banner__text"
  ],
  [
    "removeTrigger",
    "aroma-banner__removeTrigger"
  ],
  [
    "buttons",
    "aroma-banner__buttons"
  ],
  [
    "desktopLogo",
    "aroma-banner__desktopLogo"
  ],
  [
    "mobileLogo",
    "aroma-banner__mobileLogo"
  ],
  [
    "title",
    "aroma-banner__title"
  ],
  [
    "description",
    "aroma-banner__description"
  ],
  [
    "contextualIconWrap",
    "aroma-banner__contextualIconWrap"
  ],
  [
    "textWrap",
    "aroma-banner__textWrap"
  ]
]
const aromaBannerSlotFns = /* @__PURE__ */ aromaBannerSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaBannerDefaultVariants, getSlotCompoundVariant(aromaBannerCompoundVariants, slotName))])

const aromaBannerFn = memo((props = {}) => {
  return Object.fromEntries(aromaBannerSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaBannerVariantKeys = [
  "colorTheme",
  "form",
  "spot",
  "isRemovable",
  "showLogo"
]
const getVariantProps = (variants) => ({ ...aromaBannerDefaultVariants, ...compact(variants) })

export const aromaBanner = /* @__PURE__ */ Object.assign(aromaBannerFn, {
  __recipe__: false,
  __name__: 'aromaBanner',
  raw: (props) => props,
  variantKeys: aromaBannerVariantKeys,
  variantMap: {
  "colorTheme": [
    "neutral",
    "info",
    "success",
    "warning",
    "error",
    "blue",
    "oat"
  ],
  "form": [
    "bar",
    "square",
    "rectangle"
  ],
  "spot": [
    "fixed",
    "float"
  ],
  "isRemovable": [
    "true",
    "false"
  ],
  "showLogo": [
    "true",
    "false"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaBannerVariantKeys)
  },
  getVariantProps
})