import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaSectionHeaderDefaultVariants = {}
const aromaSectionHeaderCompoundVariants = []

const aromaSectionHeaderSlotNames = [
  [
    "container",
    "aroma-section-header__container"
  ],
  [
    "wrap",
    "aroma-section-header__wrap"
  ],
  [
    "title",
    "aroma-section-header__title"
  ],
  [
    "innerWrap",
    "aroma-section-header__innerWrap"
  ]
]
const aromaSectionHeaderSlotFns = /* @__PURE__ */ aromaSectionHeaderSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaSectionHeaderDefaultVariants, getSlotCompoundVariant(aromaSectionHeaderCompoundVariants, slotName))])

const aromaSectionHeaderFn = memo((props = {}) => {
  return Object.fromEntries(aromaSectionHeaderSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaSectionHeaderVariantKeys = []
const getVariantProps = (variants) => ({ ...aromaSectionHeaderDefaultVariants, ...compact(variants) })

export const aromaSectionHeader = /* @__PURE__ */ Object.assign(aromaSectionHeaderFn, {
  __recipe__: false,
  __name__: 'aromaSectionHeader',
  raw: (props) => props,
  variantKeys: aromaSectionHeaderVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, aromaSectionHeaderVariantKeys)
  },
  getVariantProps
})