import { styled } from '@zenchef/styled-system/jsx'

const Subtitle = styled('span', {
  base: {
    borderRadius: 'pill',
    px: 'padding.3',
    py: 'padding.1',
    bg: 'background.neutral-on-brand.base.subtle',
    color: 'content.neutral.bold',
    textStyle: 'mono.s.regular',
    wordBreak: 'keep-all',
    mx: 'auto',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    height: 's'
  }
})

export default Subtitle
