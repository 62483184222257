import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaToggleSwitchFn = /* @__PURE__ */ createRecipe('aroma-toggle-switch', {}, [])

const aromaToggleSwitchVariantMap = {}

const aromaToggleSwitchVariantKeys = Object.keys(aromaToggleSwitchVariantMap)

export const aromaToggleSwitch = /* @__PURE__ */ Object.assign(memo(aromaToggleSwitchFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaToggleSwitch',
  __getCompoundVariantCss__: aromaToggleSwitchFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaToggleSwitchVariantKeys,
  variantMap: aromaToggleSwitchVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaToggleSwitchVariantKeys)
  },
  getVariantProps: aromaToggleSwitchFn.getVariantProps,
})