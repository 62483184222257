import pino from 'pino'

const isLogColorized = process.env.LOG_COLORIZED === 'true'
const pinoPrettyActivated = process.env.PINO_PRETTY_ACTIVATED === 'true'

const logger = pino({
  level: 'info',
  transport: pinoPrettyActivated
    ? {
        target: 'pino-pretty',
        options: { colorize: isLogColorized, translateTime: 'yyyy-mm-dd HH:MM:ss.l' }
      }
    : undefined
})

export default logger
