import { useStripe } from '@stripe/react-stripe-js'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import bugsnagClient from '@/lib/bugsnag'
import handleError, { CheckoutFormState } from '@/utils/CheckForm/handleError'
import handleStripeError from '@/utils/CheckForm/handleStripeError'
import { isInIframe, isInSdkIframe } from '@/utils/isInIframe'
import StoresContext from '@/utils/StoresContext'

export default function useCheckRedirectFrom3DS(checkoutFormState: CheckoutFormState) {
  const router = useRouter()
  const stripe = useStripe()
  const { appStore } = useContext(StoresContext)

  useEffect(() => {
    const checkRedirectFrom3DS = async () => {
      try {
        if (isInIframe() && !isInSdkIframe(appStore)) {
          bugsnagClient.notify(
            new Error(
              'This booking widget is in an iframe that is not the sdk iframe, this may prevent some payment methods from working properly'
            ),
            (event) => {
              event.severity = 'warning'
              event.addMetadata('restaurant', 'id', appStore.state.restaurantId)
              event.addMetadata('widget integration', {
                windowSelf: window?.self?.location?.href,
                windowTop: window?.top?.location?.href,
                appStoreStateSdk: appStore?.state?.sdk,
                appStoreStateIsFullscreen: appStore?.state?.isFullscreen
              })
            }
          )
        }

        const setup_intent = appStore.state.query.setup_intent
        const setup_intent_client_secret = appStore.state.query.setup_intent_client_secret
        const wasRedirectedFrom3DS = setup_intent && setup_intent_client_secret
        if (!wasRedirectedFrom3DS || !stripe || Array.isArray(setup_intent_client_secret)) {
          return
        }
        checkoutFormState.isChecking3DS = true
        const result = await stripe.retrieveSetupIntent(setup_intent_client_secret)
        const { setupIntent, error } = result

        if (!error && setupIntent.status === 'succeeded') {
          appStore.state.setupIntentId = setupIntent.id
          await appStore.confirmImprint()

          const query = { ...appStore.state.query }
          ;['setup_intent', 'setup_intent_client_secret', 'source_type'].forEach((param) => {
            delete query[param]
          })

          await router.push({
            pathname: '/thank_you',
            query
          })
          return
        }

        checkoutFormState.isChecking3DS = false
        const stripeError = error || setupIntent.last_setup_error
        if (stripeError) {
          handleStripeError(appStore, checkoutFormState, stripeError)
        }
      } catch (error) {
        checkoutFormState.isChecking3DS = false
        handleError(appStore, checkoutFormState, error)
      }
    }

    checkRedirectFrom3DS()
  }, [appStore, checkoutFormState, router, stripe])
}
