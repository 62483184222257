import { useRouter } from 'next/router'
import { useContext } from 'react'

import useSlotSelector from '@/components/redesign/filters/SlotInput/useSlotSelector'
import AppStore from '@/stores/AppStore'
import { Bookings } from '@/types/types'
import StoresContext from '@/utils/StoresContext'

export type NextPageUrl = '/offers' | '/form'

export type NextPageUrlForSuggestedRestaurant = '/results' | NextPageUrl

const MAP_NEXTPAGE_WITH_LABEL_KEY: Record<NextPageUrl | 'default', string> = {
  default: 'search',
  '/offers': 'search',
  '/form': 'book_now'
}

const useSearchBookingWish = () => {
  const { appStore } = useContext(StoresContext)
  const router = useRouter()
  const { updateOfferSelectionWithWishData } = appStore
  const { wish, isCurrentDayOpen, isRoomMandatory } = appStore.state
  const { hasSlotsToSelect } = useSlotSelector()

  const getNextPageUrl = (slot: Bookings.Slot, shift: Bookings.Shift, isWaitlist: boolean): NextPageUrl => {
    const hasAvailableOffers = appStore.state.isShiftSlotAndWishCompatibleWithOffers(
      shift,
      slot,
      isWaitlist ? 'waitlist' : 'booking'
    )
    return hasAvailableOffers ? '/offers' : '/form'
  }

  const getNextPageUrlForSuggestedRestaurant = (
    slot: Bookings.Slot,
    shift: Bookings.Shift,
    isWaitlist: boolean
  ): NextPageUrlForSuggestedRestaurant => {
    const hasRoomSelection = appStore.state.hasRoomSelection ?? false
    if (hasRoomSelection) {
      return '/results'
    }
    return getNextPageUrl(slot, shift, isWaitlist)
  }

  const getIsWaitlist = () => {
    try {
      return getSelectedSlotShiftWaitlistFromAppStoreIfNotPassedAsParameters(appStore).isWaitlist
    } catch {
      return false
    }
  }
  const isRoomMissing = isRoomMandatory && !wish.room_id

  return {
    isRoomMissing,
    get isWaitlist() {
      return getIsWaitlist()
    },
    get nextPageUrl() {
      try {
        const { shift, slot, isWaitlist } = getSelectedSlotShiftWaitlistFromAppStoreIfNotPassedAsParameters(appStore)
        return getNextPageUrl(slot, shift, isWaitlist)
      } catch {
        return undefined
      }
    },
    goToNextPageInSuggestedRestaurant: (
      slot: Bookings.Slot,
      shift: Bookings.Shift,
      isWaitlist: boolean,
      suggestedRestaurantId: number
    ) => {
      const nextPageUrl = getNextPageUrlForSuggestedRestaurant(slot, shift, isWaitlist)
      const hasRoomSelection = appStore.state.hasRoomSelection ?? false

      const { day, room_id, pax } = appStore.state.wish

      const query = new URLSearchParams({
        rid: `${suggestedRestaurantId}`,
        day,
        pax: `${pax}`,
        ...(isWaitlist ? { waiting_list: `${isWaitlist}` } : {}),
        ...(room_id ? { room_id: `${room_id}` } : {}),
        slot: slot.name,
        'source-rid': `${appStore.state.sourceRestaurantId}`,
        ...(hasRoomSelection ? { currentFilter: 'area' } : null)
      })
      window.open(`${nextPageUrl}?${query}`, '_blank')?.focus()
    },
    goToNextPage: () => {
      const { shift, slot, isWaitlist } = getSelectedSlotShiftWaitlistFromAppStoreIfNotPassedAsParameters(appStore)

      const nextPageUrl = getNextPageUrl(slot, shift, isWaitlist)

      const { pax, day, ...restQuery } = router.query
      router.push({
        pathname: nextPageUrl,
        query: restQuery
      })
    },
    updateSelection() {
      wish.waiting_list = getIsWaitlist() ?? false
      updateOfferSelectionWithWishData()
    },
    get buttonLabelKey() {
      const { isInUpdateFlow, selectedHighlightedOffer, isSelectedHighlightedOfferAvailable } = appStore.state
      const { isWaitlist, nextPageUrl } = this
      if (isWaitlist) {
        return 'book_waiting_list'
      }
      if (isInUpdateFlow) {
        return 'book_modify_booking'
      }
      if (selectedHighlightedOffer && isSelectedHighlightedOfferAvailable()) {
        return 'book_now'
      }
      if (selectedHighlightedOffer && !isSelectedHighlightedOfferAvailable() && nextPageUrl === '/offers') {
        return 'book_other_offer'
      }
      if (selectedHighlightedOffer && !isSelectedHighlightedOfferAvailable() && nextPageUrl === '/form') {
        return 'book_without_selected_offer'
      }

      return MAP_NEXTPAGE_WITH_LABEL_KEY[nextPageUrl ?? 'default']
    },
    get accordionItemToOpen() {
      if (!isCurrentDayOpen) {
        return 'calendar'
      }
      if (isRoomMissing) {
        return 'area'
      }
      if (hasSlotsToSelect && !wish.slot) {
        return 'slot'
      }
      return undefined
    }
  }
}

/**
 * We need to keep the possibility to pass slot, shift, waitlist to the function
 *  when selecting a slot in Results.tsx (old Ui still used in suggestions)
 * This is a utility function to get
 */
const getSelectedSlotShiftWaitlistFromAppStoreIfNotPassedAsParameters = (
  appStore: AppStore,
  selectedSlot?: Bookings.Slot,
  selectedShift?: Bookings.Shift,
  selectedWaitlist?: boolean
) => {
  const shift = selectedShift ?? appStore.state.selectedShift
  const slot = selectedSlot ?? appStore.state.selectedSlot

  if (!slot || !shift) {
    throw new Error('unimplemented behaviour - no slot or shift passed as parameter on in app store')
  }

  const isSlotAvailable = appStore.state.isSlotAvailable(slot, shift)
  const isSlotAvailableForWaitlist = appStore.state.isSlotAvailableForWaitlist(slot, shift)

  if (!isSlotAvailable && !isSlotAvailableForWaitlist) {
    throw new Error('unimplemented behaviour - no waitlist or booking available')
  }

  const isWaitlist = selectedWaitlist ?? (!isSlotAvailable && isSlotAvailableForWaitlist)

  return { shift, slot, isWaitlist }
}

export default useSearchBookingWish
